import { Pharmacy } from '@aposphaere/core-kit'
import React, { useEffect, useState, useRef } from 'react'
import ActionPanel from '../../components/ActionsPanel'
import ActivePharmaciesViewTable from '../../components/ActivePharmaciesViewTable'
import Map, { CustomDirectionsRenderer } from '../../components/Map'
import InfoWindowContainer from '../../components/Map/InfoWindowContainer'
import SimpleGoogleMap from '../../components/Map/SimpleGoogleMap'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { usePharmacyFilterContext } from '../../contexts/filterContext'
import { usePharmacyQuery } from '../../hooks/graphql'
/*
 * PROPS
 */
interface Props {
  setSelectedPharmacy: (id: number | undefined) => void
  selectedPharmacyId: number | undefined
  onCreateAppointmentClick: (id?: number | undefined) => void
  onOpenPharmacyDetails: () => void
  onCloseInfoWindow: () => void
  routeForAppointment: boolean
}
/*
 * COMPONENT
 */
const MapView: React.FC<Props> = ({
  setSelectedPharmacy,
  onCloseInfoWindow,
  onOpenPharmacyDetails,
  onCreateAppointmentClick,
  selectedPharmacyId,
  routeForAppointment,
}) => {
  const { isShowRouteWithAllPharmacy, sortedAppointmentsToday, pharmaciesHasAppointmentsToday, selectedDay } = useRoutePlanner()

  const [mode, setMode] = useState<'map' | 'pharmacy-list'>('map')
  const { filteredPharmacies, dispatchFilterState } = usePharmacyFilterContext()
  const [pharmacyArray, setPharmacyArray] = useState<Pharmacy[]>([])
  const { data: activePharmacy } = usePharmacyQuery(selectedPharmacyId)
  const [exsistArray, setExsistArray] = useState(false)
  const prevArrayaRef = useRef<any[]>([])
  useEffect(() => {
    if (sortedAppointmentsToday?.length && pharmaciesHasAppointmentsToday?.length && !isShowRouteWithAllPharmacy && activePharmacy) {
      setPharmacyArray([...pharmaciesHasAppointmentsToday, activePharmacy])
      setExsistArray(true)
      prevArrayaRef.current = [...pharmaciesHasAppointmentsToday, activePharmacy]
    } else if (sortedAppointmentsToday?.length && pharmaciesHasAppointmentsToday?.length && exsistArray && !activePharmacy) {
      setPharmacyArray(pharmaciesHasAppointmentsToday)
    } else if (sortedAppointmentsToday?.length && pharmaciesHasAppointmentsToday?.length && exsistArray) {
      setPharmacyArray(prevArrayaRef.current)
    } else if (sortedAppointmentsToday?.length && pharmaciesHasAppointmentsToday?.length && !isShowRouteWithAllPharmacy) {
      setPharmacyArray(pharmaciesHasAppointmentsToday)
    } else if (pharmaciesHasAppointmentsToday?.length && !sortedAppointmentsToday?.length && activePharmacy) {
      setPharmacyArray([...pharmaciesHasAppointmentsToday, activePharmacy])
    } else if (pharmaciesHasAppointmentsToday?.length && !sortedAppointmentsToday?.length) {
      setPharmacyArray(pharmaciesHasAppointmentsToday)
    } else if (activePharmacy && !sortedAppointmentsToday?.length && routeForAppointment) {
      setPharmacyArray([activePharmacy])
    } else {
      setPharmacyArray(filteredPharmacies)
    }
  }, [
    filteredPharmacies,
    sortedAppointmentsToday,
    pharmaciesHasAppointmentsToday,
    isShowRouteWithAllPharmacy,
    dispatchFilterState,
    routeForAppointment,
    activePharmacy,
    selectedDay
  ])
  const appointmentsToday = sortedAppointmentsToday || []
  const activeFarmacyForRouting = activePharmacy && routeForAppointment

  if (mode === 'map') {
    return (
      <SimpleGoogleMap>
        <Map pharmacies={pharmacyArray} onClick={setSelectedPharmacy}>
          <div className="fixed mt-0 mx-2 right-0 w-1/2 2lg:w-3/4 flex justify-end ">
            <ActionPanel
              setSelectedPharmacy={setSelectedPharmacy}
              viewMode={'map'}
              activateListView={() => setMode('pharmacy-list')}
              activateMapView={() => setMode('map')}
            />
          </div>
          {(appointmentsToday.length > 0 || activeFarmacyForRouting) && (
            <CustomDirectionsRenderer sortedAppointmentsToday={appointmentsToday} activePharmacy={activePharmacy} />
          )}
          {
            <InfoWindowContainer
              onClose={onCloseInfoWindow}
              selectedPharmacyId={selectedPharmacyId}
              setSelectedPharmacy={setSelectedPharmacy}
              onCreateAppointmentClick={() => onCreateAppointmentClick()}
              onPharmacyDetailClick={onOpenPharmacyDetails}
              routeForAppointment={routeForAppointment}
            />
          }
        </Map>
      </SimpleGoogleMap>
    )
  }

  if (mode === 'pharmacy-list') {
    return (
      <div className={'sticky px-4 top-0 w-full overflow-auto max-h-screen'}>
        <div className="sticky top-0 z-50 mt-4 mb-5 w-full flex justify-end">
          <ActionPanel
            setSelectedPharmacy={setSelectedPharmacy}
            viewMode={'list'}
            activateListView={() => setMode('pharmacy-list')}
            activateMapView={() => setMode('map')}
          />
        </div>
        <ActivePharmaciesViewTable pharmacies={pharmacyArray} onCreateAppointment={onCreateAppointmentClick} />
      </div>
    )
  }
  return null
}

export default MapView
